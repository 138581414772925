import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import {config} from "../../../shared/services/config";
import { constants } from '../../../shared/services/constants';
import {HeaderService} from '../../../shared/services/headerservice';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
@inject(DialogController,ProfileService,JwtService,LeavemanagementService,HeaderService,LoginService,Router)
export class ViewApplyTimeOffPopup {
    static inject = [DialogController,Element];
    isDragging = false;
    dialog;
    pageSize = 10;
    topicsPageSize=10;
    OnlineTopic = [];
    additionalcomments = [];
    CategoryId=constants.leaveworkflowcategoryid;
    constructor(controller, ProfileService,JwtService,LeavemanagementService,headerservice,loginservice,router,element) {
        this.headerservice = headerservice;
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.leavemanagementService = LeavemanagementService;
        this.empid = this.jwtService.getEmployeeId();
        this.router = router;
        this.loginservice = loginservice;
        this.element = element;
    }
    activate(viewData){
        this.leavemanagement = viewData.LeaveApplicationID;
        this.LeaveApplicationIDS=viewData.split(',');
        const firstValue =this.LeaveApplicationIDS[0];
        this.LeaveApplicationID=firstValue;
        if(this.LeaveApplicationIDFromSave > 1){
            this.LeaveApplicationID = this.LeaveApplicationIDFromSave;
        }
        if(viewData> 1){
            this.message = "Time off applied successfully"
        }
    }
    downloadFile() {
        const downloadUrl = config.img_url+ this.Files;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.download = downloadUrl;
        link.click();
    }
    attached(){
        this.leavemanagementService.GetLeaveApplicationDetails(this.LeaveApplicationID).then(data=>
        {
            this.timeoffRequest=data;
        });
        this.leavemanagementService.GetMultiuLeaveApplicationDetails(this.LeaveApplicationIDS).then(data=>
        {
            this.MultiutimeoffRequest=data;
        });
        this.leavemanagementService.GetAdditionalComments(this.LeaveApplicationID,this.CategoryId)
            .then(data =>{
                this.additionalcomments = data;
                this.Files =  this.additionalcomments.map(option => option.DocumentPath);
                if ( this.additionalcomments.map(option => option.DocumentPath)) {
                    this.file =  this.additionalcomments.map(option => option.DocumentPath);
                    this.DocumentName = this.additionalcomments.map(option => option.DocumentPath);
                }
            });
            this.headerservice.dynamicHeader(this.empid)
                .then(data => {
                    this.employeedetails = data;
                    this.userPhoto=config.img_url + data.PhotoPath;
                    this.designation = data.Designation;
                });
    }
    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);
        if (date1 === date2) {
            return 0;
        }
        if (date1 > date2) {
            return 1 * sortOrder;
        }
        return -1 * sortOrder;
    }
    nameLength(row) {
        return row.Type.length;
    }
    cancel(){
        this.controller.cancel();
        this.router.navigatetoroute('employeetimeoffdetails');
    }
}
