import {inject,bindingMode,bindable } from 'aurelia-framework';
import { JwtService } from '../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { constants} from '../../../shared/services/constants';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,JwtService,LeavemanagementService,DialogService,LoginService,Router)
export class ViewMyTimeoffSummaryComponent{
    //LeaveType = "";
    leaveViewList = []; 
    isLoading = false;
    FavoritesExist = false;
    sitemapId=constants.mytimeoffsummarysitemapid;
    static inject = [DialogController,Element];
    isDragging = false;
    dialog;

    constructor(controller,JwtService,LeavemanagementService,DialogService,loginservice,router){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.loginservice = loginservice;
    }
    activate(viewData){
        this.LeaveTypeId =viewData ;
    }
    attached(){
        this.isLoading = true;
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        this.leavemanagementService.GetMyTimeOffSummaryByLeaveTypeId(this.empid,this.LeaveTypeId).then(data=>
        {
            this.LeaveTypeName=data.LeaveTypeName;
            this.LeaveCreditDays=data.LeaveCreditDays;
            this.LeaveAppliedDays=data.LeaveAppliedDays;
            this.ScheduleBalance=data.ScheduleBalance;
            this.LeaveBalanceDays=data.LeaveBalanceDays;
            this.Pendingforapproval=data.Pendingforapproval;
            this.ApprovedLossofPay=data.ApprovedLossofPay;
            this.Nooflopspending=data.Nooflopspending;
            this.CanceledLeaves=data.CanceledLeaves;
            this.isLoading = false;
        });
    }
   
    cancel(){
        this.controller.cancel();
    }
   
}
