import { DialogController } from 'aurelia-dialog';
import {inject} from 'aurelia-framework';
import { JwtService } from '../../../shared/services/jwtservice';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import {config} from "../../../shared/services/config";
@inject(DialogController,LeavemanagementService,JwtService)
export class ViewEmployeeTimeOffDetailsComponent {
    static inject = [DialogController,Element];
    
    pageSize = config.grid_PazeSize;
   // isLoading = false;
    constructor(dialogController,LeavemanagementService,JwtService,element) {
        this.dialogController = dialogController;
        this.element = element;
        this.leavemanagementService = LeavemanagementService;
        this.jwtService= JwtService;
        this.empid = this.jwtService.getEmployeeId();

    }
    activate(modeldata) {

        this.statuscode = modeldata.headerText;
        this.LeaveTypeID = modeldata.leaveData.LeaveTypeId;
        this.EmployeeID=modeldata.leaveData.EmployeeId;
        this.Year=modeldata.leaveData.Year;
        

    }
    attached(){
         // this.isLoading = true;
        this.leavemanagementService.ViewEmployeeTimeOffgrid(this.statuscode,this.EmployeeID,this.LeaveTypeID,this.Year)
            .then(data => {
                this.GridData = data.List;
                // this.isLoading = false;
                

            })
    }

    cancel() {
        this.dialogController.cancel();
    }

}