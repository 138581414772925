import {inject } from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import {config} from "../../../shared/services/config";
import { constants } from '../../../shared/services/constants';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,LeavemanagementService,LoginService,Router)
export class Viewtimeoffrequestcomponent {
    pageSize = 10;
    topicsPageSize=10;
    OnlineTopic = [];
    additionalcomments = [];
    static inject = [DialogController,Element];
    isDragging = false;
    dialog;

    CategoryId=constants.leaveworkflowcategoryid;
    constructor(controller, ProfileService,JwtService,LeavemanagementService,loginservice,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.leavemanagementService = LeavemanagementService;
        this.empid = this.jwtService.getEmployeeId();
        this.router = router;
        this.loginservice = loginservice;

    }
    activate(viewData){
        this.LeaveApplicationIDFromSave = viewData.viewdata;
        this.LeaveApplicationIDFromView = viewData.leaveId;
        this.EmployeePic = viewData.PhotoPath;
        this.LeaveApplicationID=viewData.selectedRecordId;
        if(this.LeaveApplicationIDFromSave > 1){
            this.LeaveApplicationID = this.LeaveApplicationIDFromSave;
        }
        if(this.LeaveApplicationIDFromView > 1){
            this.LeaveApplicationID = this.LeaveApplicationIDFromView;
        }
        if(this.LeaveApplicationIDFromSave > 1){
            this.message = "Time off applied successfully"
        }
    }
    downloadFile() {
        const downloadUrl = config.img_url+ this.Files;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.download = downloadUrl;
        link.click();
    }
    async bind() {
        setTimeout(()=>{
            Scrollbar.init(document.querySelector('.viewtimeoff-pop-scrollbar'));
        },1000);
        this.userPhoto = config.img_url + this.EmployeePic;
    }
    attached(){
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        this.leavemanagementService.GetLeaveApplicationDetails(this.LeaveApplicationID).then(data=>
        {
            this.timeoffRequest=data;
        });
        this.leavemanagementService.GetAdditionalComments(this.LeaveApplicationID,this.CategoryId)
            .then(data =>{
                this.additionalcomments = data;
                this.Files =  this.additionalcomments.map(option => option.DocumentPath);
                if ( this.additionalcomments.map(option => option.DocumentPath)) {
                    this.file =  this.additionalcomments.map(option => option.DocumentPath);
                    this.DocumentName = this.additionalcomments.map(option => option.DocumentPath);
                }
            });
    }
    RefreshProfileImage(data){
        this.userPhotoElement = document.querySelector('.rounded-full.dropdown.inline-block');
        this.userPhotoElement.src = data;
    }
    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);
        if (date1 === date2) {
            return 0;
        }
        if (date1 > date2) {
            return 1 * sortOrder;
        }
        return -1 * sortOrder;
    }
    nameLength(row) {
        return row.Type.length;
    }
    

    Cancel(){
        this.controller.cancel();
        this.router.navigateToRoute('employeetimeoffdetails');
    }
}
