import {inject } from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import {config} from "../../../shared/services/config";
import { constants } from '../../../shared/services/constants';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,LeavemanagementService,LoginService,Router)
export class Viewtimeoffadjustmentcomponent {
    pageSize = 10;
    topicsPageSize=10;
    OnlineTopic = [];
    additionalcomments = [];
    static inject = [DialogController,Element];
    isDragging = false;
    dialog;

    CategoryId=constants.CreditLeaveApprovalWorkflowCategoryId;
    constructor(controller, ProfileService,JwtService,LeavemanagementService,loginservice,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.leavemanagementService = LeavemanagementService;
        this.empid = this.jwtService.getEmployeeId();
        this.router = router;
        this.loginservice = loginservice;

    }
    activate(viewData){
        console.log(viewData);
        this.LeaveApplicationIDFromSave = viewData.viewdata;
        // this.LeaveApplicationIDFromView = viewData.leaveId;
        // this.EmployeePic = viewData.PhotoPath;
        this.LeaveCreditId=viewData.LeaveCreditId;
        this.LeaveTypid=viewData.LeaveTypeID;
        this.EmployeeID=viewData.EmployeeId;
        this.Type =viewData.Type;
        this.DepartmentName =viewData.DepartmentName;
        this.EmployeeName =viewData.EmployeeName;
        this.SendMailIND = this.showCertificationFields,
        this.DateOfJoining=viewData.DateOfJoining;
        console.log( this.EmployeeName);
        // if(this.LeaveApplicationIDFromSave > 1){
        //     this.LeaveApplicationID = this.LeaveApplicationIDFromSave;
        // }
        // if(this.LeaveApplicationIDFromView > 1){
        //     this.LeaveApplicationID = this.LeaveApplicationIDFromView;
        // }
        if(this.LeaveApplicationIDFromSave > 1){
            this.message = "Time off applied successfully"
        }
    }
    downloadFile() {
        const downloadUrl = config.img_url+ this.Files;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.download = downloadUrl;
        link.click();
    }
    async bind() {
        setTimeout(()=>{
            Scrollbar.init(document.querySelector('.viewtimeoff-pop-scrollbar'));
        },1000);
        this.userPhoto = config.img_url + this.EmployeePic;
    }
    attached(){
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        console.log(this.LeaveCreditId,this.LeaveTypid,this.EmployeeID);
        this.leavemanagementService.GetLeaveCreditsEditView(this.LeaveCreditId,this.LeaveTypid,this.EmployeeID).then(data=>
        {
            this.timeoffRequest=data;
            console.log(this.timeoffRequest);
        });


        this.leavemanagementService.GetAdditionalComments(this.LeaveCreditId,this.CategoryId)
            .then(data =>{
                this.additionalcomments = data;
                console.log(this.additionalcomments);
                this.Files =  this.additionalcomments.map(option => option.DocumentPath);
                if ( this.additionalcomments.map(option => option.DocumentPath)) {
                    this.file =  this.additionalcomments.map(option => option.DocumentPath);
                    this.DocumentName = this.additionalcomments.map(option => option.DocumentPath);
                }
            });
    }
    RefreshProfileImage(data){
        this.userPhotoElement = document.querySelector('.rounded-full.dropdown.inline-block');
        this.userPhotoElement.src = data;
    }
    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);
        if (date1 === date2) {
            return 0;
        }
        if (date1 > date2) {
            return 1 * sortOrder;
        }
        return -1 * sortOrder;
    }
    nameLength(row) {
        return row.Type.length;
    }
   

    cancel(){
        this.controller.cancel();
    }
}
